import { CardContent } from "@mui/material";
import { type Agent } from "@src/appV2/Agents";
import { useAgentProfilePicture } from "@src/appV2/Agents/api/useAgentProfilePicture";

import { useAgentStats } from "../../api/useAgentStats";
import { ReferralCardSkeleton } from "../ReferralCardSkeleton";
import { WorkplaceReferralCard } from "../WorkplaceReferralCard/WorkplaceReferralCard";
import { PromoteYourProfileCard } from "./PromoteYourProfileCard";

interface PromoteYourProfileProps {
  agent: Agent;
  referralCode: string;
}

export function PromoteYourProfile(props: PromoteYourProfileProps) {
  const { agent, referralCode } = props;
  // We can show empty avatar, then replacing it with agent's avatar
  // since agent's avatar is minor which shouldn't affect the flow in case of fail loading agent's avatar
  const { data: agentProfilePicture } = useAgentProfilePicture(agent.userId);
  const {
    isLoading: isAgentStatsLoading,
    data: agentStatsData,
    isSuccess: isAgentStatsSuccess,
  } = useAgentStats({ agentId: agent.userId });

  return (
    <WorkplaceReferralCard>
      {isAgentStatsLoading && (
        <CardContent>
          <ReferralCardSkeleton height={{ title: 24, body: 48 }} count={1} />
        </CardContent>
      )}
      {agentStatsData && isAgentStatsSuccess && (
        <PromoteYourProfileCard
          agent={agent}
          avatarUrl={agentProfilePicture?.distribution_url}
          agentStats={agentStatsData}
          referralCode={referralCode}
        />
      )}
    </WorkplaceReferralCard>
  );
}
